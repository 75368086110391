import React from 'react'
import './Join.css'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
const Join = () => {
    const form =useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_04mloo9', 'template_xeq2eo4', form.current, 'JRB0GfFzODxQYcBTA')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
   <div className="join" id='join-us'>
        <div className="left-j">
            <hr />
            <div>
                <span className='stroke'>READY TO</span>{"  "}
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>{" "}
                <span className='stroke'>WITH US?</span>
            </div>

        </div>
        <div className="right-j">
            <form ref={form} className='email-container' onSubmit={sendEmail}>
                <input type="email" name='user_email' placeholder='Enter your email'  />
                <button>Join Now</button>
            </form>
        </div>
   </div>
  )
}

export default Join